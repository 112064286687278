import React, { useState, useEffect, useMemo, useRef } from "react";
import classes from "./Register.module.css";
import { ReCaptcha } from "react-recaptcha-google";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../Store/Actions/index";
import * as actionTypes from "../../Store/Actions/ActionTypes";
import Toast from "../../common/Toast/Toast";
import img from "../../Assets/Images/4.png";
// import envelop from "../../Assets/Images/envelop.png";
// import key from "../../Assets/Images/key.png";
// import { NavLink } from "react-router-dom";
// import facebook from "../../Assets/Images/facebook.png";
// import twitter from "../../Assets/Images/twitter.png";
// import googlePlus from "../../Assets/Images/googlePlus.png";
// import linkedIn from "../../Assets/Images/linkedIn.png";

const Register = (props) => {
  const recaptchRef = useRef();
  const [formData, setFormData] = useState({});
  const [activeSignup, setActiveSignup] = useState("employer");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [toast, setToast] = useState(false);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const { loader, errorMsg, emailSent, signUpSuccessMsg, msg } = useSelector(
    (state) => ({
      loader: state.AuthReducer.loader,
      errorMsg: state.AuthReducer.errorMsg,
      emailSent: state.AuthReducer.emailSent,
      signUpSuccessMsg: state.AuthReducer.signUpSuccessMsg,
      msg: state.AuthReducer.msg,
    })
  );

  const handleFieldChanges = (e) => {
    setError({});
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(actions.signup({ ...formData, type: activeSignup }));
    }
  };

  useEffect(() => {
    if (errorMsg) {
      setToast(
        true,
        setTimeout(() => {
          setToast(false);
          dispatch({ type: actionTypes.CLEAR });
        }, 3000)
      );
    }
  }, [errorMsg]);

  const validate = () => {
    let { name, mobile, password, confirm } = formData;
    let status = true;
    let errorLocal = {};
    if (!name) {
      status = false;
      errorLocal = { ...errorLocal, ["name"]: "invalid name" };
    }
    if (password != confirm) {
      status = false;
      errorLocal = { ...errorLocal, ["confirm"]: "invalid confirm" };
    }

    if (!isCaptchaVerified) {
      status = false;
      errorLocal = { ...errorLocal, ["captcha"]: "captcha error" };
    }

    setError(errorLocal);

    return status;
  };

  // const handleRecaptchWidth = () => {
  //   if(window.Screen.availWidth < 800){
  //     return 'compact'
  //   }else{
  //     return 'normal'
  //   }
  // }

  const goToLogin = () => {
    dispatch({ type: actionTypes.CLEAR });
    props.history.push("/login");
  };

  const onLoadRecaptcha = () => {};

  const verifyCallback = () => {
    setIsCaptchaVerified(!isCaptchaVerified);
  };

  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.CLEAR });
    };
  }, []);

  const changeTab = (name) => {
    recaptchRef.current.reset();
    dispatch({ type: actionTypes.CLEAR });
    setActiveSignup(name);
    setFormData({
      confirm: "",
      password: "",
      mobile: "",
      email: "",
      name: "",
    });
  };

  if (signUpSuccessMsg === "newSignup") {
    return (
      <div className={classes.modal}>
        <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
        <div>
          We have sent an email to {formData.email} . click on the verification
          link to activate your account.
        </div>
        <div className={classes.goToBtn} onClick={() => goToLogin()}>
          Go to login
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.register}>
        <div className={classes.left}></div>
        <div className={classes.right}>
          <div className={classes.tab}>
            <div
              className={activeSignup === "employer" ? classes.active : ""}
              onClick={() => {
                changeTab("employer");
              }}
            >
              Register as Employer
            </div>
            <div
              className={activeSignup === "candidate" ? classes.active : ""}
              onClick={() => {
                changeTab("candidate");
              }}
            >
              Register as Job Seeker
            </div>
          </div>
          <form onSubmit={submitForm}>
            <div className={classes.input}>
              <input
                style={{ border: error["name"] && "1px solid red" }}
                type="text"
                autoComplete="off"
                onChange={(e) => handleFieldChanges(e)}
                maxLength="50"
                pattern="^[a-zA-Z ]*$"
                title="Please enter a valid name"
                placeholder={
                  activeSignup === "candidate" ? "Name" : "Employer Name"
                }
                name="name"
                value={formData.name}
                required
              />
            </div>
            <div className={classes.input1}>
              <div className={classes.inputLeft}>
                <input
                  type="email"
                  autoComplete="off"
                  placeholder="Email"
                  onChange={(e) => handleFieldChanges(e)}
                  name="email"
                  value={formData.email}
                  required
                  pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                  title="Please enter a valid Email Id"
                />
              </div>
              <div className={classes.inputRight}>
                <input
                  type="text"
                  required
                  pattern="^[0-9]*$"
                  title="Only numbers are allowed"
                  autoComplete="off"
                  placeholder="Mobile"
                  minLength="10"
                  maxLength="10"
                  onChange={(e) => handleFieldChanges(e)}
                  name="mobile"
                  value={formData.mobile}
                  required
                />
              </div>
            </div>
            <div className={classes.input1}>
              <div className={classes.inputLeft}>
                <input
                  type={passwordShow ? "text" : "password"}
                  autoComplete="off"
                  placeholder="Enter Password"
                  onChange={(e) => handleFieldChanges(e)}
                  name="password"
                  value={formData.password}
                  minLength="7"
                  maxLength="15"
                  pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$"
                  title="Password must contain minimum 7 characters, atleast 1 letter, 1 number and 1 special character"
                  required
                />
                {passwordShow ? (
                  <i
                    class="fa fa-eye"
                    onClick={() => setPasswordShow(!passwordShow)}
                  ></i>
                ) : (
                  <i
                    class="fa fa-eye-slash"
                    onClick={() => setPasswordShow(!passwordShow)}
                  ></i>
                )}
              </div>
              <div className={classes.inputRight}>
                <input
                  style={{ border: error["confirm"] && "1px solid red" }}
                  type={confirmPasswordShow ? "text" : "password"}
                  autoComplete="off"
                  placeholder="Confirm Password"
                  onChange={(e) => handleFieldChanges(e)}
                  name="confirm"
                  value={formData.confirm}
                />
                {confirmPasswordShow ? (
                  <i
                    class="fa fa-eye"
                    onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                  ></i>
                ) : (
                  <i
                    class="fa fa-eye-slash"
                    onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                  ></i>
                )}
              </div>
            </div>
            <div className={classes.error}>
              {signUpSuccessMsg === "emailAlreadyExists" && msg}
            </div>
            <div
              className={classes.recaptcha}
              style={{ border: error["captcha"] && "1px solid red" }}
            >
              {window.innerWidth < 800 && (
                <ReCaptcha
                  render="explicit"
                  size={"compact"}
                  sitekey="6LdhpOwqAAAAAPDEQKrbHhK9wv2m_P_iwGc-boYZ"
                  onloadCallback={onLoadRecaptcha}
                  verifyCallback={verifyCallback}
                  ref={recaptchRef}
                />
              )}
              {window.innerWidth > 800 && (
                <ReCaptcha
                  render="explicit"
                  size={"normal"}
                  sitekey="6LdhpOwqAAAAAPDEQKrbHhK9wv2m_P_iwGc-boYZ"
                  onloadCallback={onLoadRecaptcha}
                  verifyCallback={verifyCallback}
                  ref={recaptchRef}
                />
              )}
            </div>
            <div className={classes.loader}>{loader && "Please wait..."}</div>
            <div className={classes.submit}>
              <button
                type="submit"
                className={loader ? classes.disabled : classes.enabled}
              >
                Sign Up
              </button>
            </div>
          </form>
          {/* <div className={classes.divider}>
              <div></div>
              <div>or</div>
              <div></div>
            </div> */}
          {/* <div className={classes.social}>
              <p>Sign Up with your Social Account</p>
              <div>
                <img src={facebook} />
                <img src={twitter} />
                <img src={linkedIn} />
                <img src={googlePlus} />
              </div>
            </div> */}
        </div>

        <Toast display={toast} type="danger" message={errorMsg} />
      </div>
    );
  }
};

export default Register;
